import Layout, { LayoutProps } from "@crafthunt-layout/Layout/Layout";
import { useTranslation } from "next-i18next";
import NextImage from "next/image";
import Link from "next/link";
import professions_community from "../../assets/Professions_Community.png";

const Maintenance = (props: LayoutProps) => {
  const { t } = useTranslation();
  return (
    <Layout {...props} hideFromRobots>
      <div className="my-10 flex flex-col items-center justify-center px-6 text-center text-white">
        <h1 className="text-2xl font-bold">{t("maintenance-page-title")}</h1>
        <p className="text-lg">{t("maintenance-page-description")}</p>
        <div className="mt-5">
          <NextImage
            width={300}
            height={300}
            src={professions_community.src}
            alt="maintenance"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </div>
        <div className="mb-5">
          <Link
            href="/"
            className="inline-flex items-center rounded-sm border border-transparent bg-white px-4 py-2 text-sm font-medium text-black sm:hover:bg-white/50"
          >
            {t("404-button")}
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Maintenance;
